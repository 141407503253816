body {
  background: url("banner.7abe4fc3.jpg") center / cover repeat-y;
  height: 100%;
}

body, html {
  height: 100%;
  margin: 0;
}

.cardBox, .center {
  width: 50%;
  margin-top: 5%;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.lightning:before {
  content: "";
  opacity: 1;
  z-index: -1;
  background-image: url("lightning.a63ca46c.png");
  width: 100%;
  height: 100%;
  animation: 4s linear infinite lightining;
  position: absolute;
  top: -100px;
  transform: rotate(180deg);
}

@keyframes lightining {
  0% {
    opacity: 0;
  }

  20% {
    opacity: 0;
  }

  21% {
    opacity: 1;
  }

  25% {
    opacity: 0;
  }

  30% {
    opacity: 0;
  }

  31% {
    opacity: 1;
  }

  35% {
    opacity: 0;
  }

  90% {
    opacity: 0;
  }

  92% {
    opacity: 1;
  }

  95% {
    opacity: 0;
  }

  98% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

a:hover, a:visited, a:link, a:active {
  text-decoration: none;
}
/*# sourceMappingURL=index.51ee78c0.css.map */
