body {
  background: url(banner.jpg);
  height: 100%;

  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: repeat-y;
  background-size: cover;
}

body, html {
  height: 100%;
  margin: 0;
}

.cardBox {
  margin-top: 5%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}

.center {
  margin-top: 5%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}

.lightning::before{
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: url(lightning.png);
  top: -100px;
  transform: rotate(180deg);
  animation: lightining 4s linear infinite;
  opacity: 1;
  z-index: -1;
}
@keyframes lightining{
  0%{
      opacity: 0;
  }
  20%{
      opacity: 0;
  }
  21%{
      opacity: 1;
  }
  25%{
      opacity: 0;
  }
  30%{
      opacity: 0;
  }
  31%{
      opacity: 1;
  }
  35%{
      opacity: 0;
  }
  90%{
    opacity: 0;
  }
  92%{
    opacity: 1;
  }
  95%{
    opacity: 0;
  }
  98%{
    opacity: 1;
  }
  100%{
      opacity: 0;
  }
}

a:hover, a:visited, a:link, a:active {
  text-decoration: none;
}